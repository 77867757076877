import './index.scss'
// import Swiper from 'swiper/swiper-bundle.min.js'
import Swiper from 'swiper/js/swiper.min.js'

// var pagenumber = localStorage.getItem("pagenumber") ? localStorage.getItem("pagenumber"):0

// banner切换
var swiperBanner = new Swiper('.index-banner .swiper-container', {
    effect: 'fade',
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.index-banner .swiper-pagination',
        type: 'fraction',
        formatFractionTotal: function (number) {
            if (number < 10) {
                return '0' + number;
            }else{
                return number;
            }
        },
        formatFractionCurrent: function (number) {
            if (number < 10) {
                return '0' + number;
            } else {
                return number;
            }
        },
    },
    navigation: {
        nextEl: '.index-banner .swiper-button-next',
        prevEl: '.index-banner .swiper-button-prev',
    },
});


let $scrollTextEl = $('.scroll-text')
let $swiperAsideEl = $('.swiper-aside')
let $asideDianEl = $('.swiper-aside .dian')
let $asideMoreEl = $('.swiper-aside .more-link')
let $asideMoreTextEl = $('.swiper-aside .more-link .text')
let $asideMoreIconEl = $('.swiper-aside .more-link .icon')
let $swiperBottomEl = $('.swiper-bottom')
let $swiperBottomContEl = $('.swiper-bottom .cont')
let $swiperBottomCircleEl = $('.swiper-bottom .cont .yuan')
let $swiperBottomAnimateCircleEl = $('.swiper-bottom .cont .center')
let $swiperBottomRightTextEl = $('.swiper-bottom .right')
let $pageSwiperEl = $('.page-swiper')
let $rightPageContEl = $('.right-page .page-title .cont')


// 初始化 aside 切换内容元素的 间隔时间
$scrollTextEl.each((i, el) => {
    let $el = $(el)
    $el.css({
        transitionDelay: 0.04 * i + 's'
    })
})

// page切换
var swiperPage = new Swiper('.page-swiper .swiper-container', {
    direction: 'vertical',
    speed: 600,
    pagination: {
        el: '.page-swiper .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.right-page .page-next',
        prevEl: '.right-page .page-prev',
    },
    mousewheel: {
        eventsTarged: '.comp-root', //鼠标在页面中任意地方都可控制swiper
    },
    on: {
        init() {
            swiperChange.call(this)
        },
        transitionStart() {
            swiperChange.call(this)
        }
    }
});
// swiperPage.slideTo(pagenumber,0)

// 需要 swiper this
function swiperChange() {
    let i = this.realIndex
    // localStorage.setItem("pagenumber", this.realIndex);
    // console.log($('.page-swiper .swiper-slide').eq(this.realIndex).data('id'), localStorage.getItem("pagenumber"))

    // 改变 右侧 标签 中的内容
    $rightPageContEl.css({
        transform: `translateX(${100 / 6 * i}%)`
    })

    // 改变 aside center 中的内容
    $scrollTextEl.css({
        transform: `translateY(-${100 / 6 * i}%)`
    })
    // aside 详细了解按钮不同链接
    $asideMoreEl.attr('href',$asideMoreEl.data(`href-${i}`))

    // 根据不同屏幕进行特殊操作
    switch (i) {
        case 0: {
            $pageSwiperEl.css('z-index', 1).removeClass('show-all');
            $swiperAsideEl.css({
                'opacity': 0,
                'visibility': 'hidden'
            });
            $swiperBottomEl.css({
                'opacity': 1,
                'visibility': 'visible'
            })
            $swiperBottomContEl.css('background','#252d67')
            $swiperBottomCircleEl.css('border-color', '#5fb4ef')
            $swiperBottomAnimateCircleEl.css('background', '#fff')
            $swiperBottomRightTextEl.css('color','#a8abc2')
            break
        }
        case 1: {
            $pageSwiperEl.css('z-index', 3).removeClass('show-all');
            $swiperAsideEl.css({
                'opacity': 1,
                'visibility': 'visible'
            });
            $asideDianEl.css('background', '#469fdd')
            $asideMoreTextEl.css('color', '#a8abc2')
            $asideMoreIconEl.css('background', '#469fdd')
            $swiperBottomEl.css({
                'opacity': 1,
                'visibility': 'visible'
            })
            $swiperBottomContEl.css('background', '#fff')
            $swiperBottomCircleEl.css('border-color', '#b2b2b2')
            $swiperBottomAnimateCircleEl.css('background', '#dcdcdc')
            $swiperBottomRightTextEl.css('color', '#666666')
            break
        }
        case 2: {
            $pageSwiperEl.css('z-index', 3).removeClass('show-all');
            $swiperAsideEl.css({
                'opacity': 1,
                'visibility': 'visible'
            });
            $asideDianEl.css('background', '#cccccc')
            $asideMoreTextEl.css('color', '#ababab')
            $asideMoreIconEl.css('background', '#469fdd')
            $swiperBottomEl.css({
                'opacity': 1,
                'visibility': 'visible'
            })
            $swiperBottomContEl.css('background', '#469fdd')
            $swiperBottomCircleEl.css('border-color', '#fff')
            $swiperBottomAnimateCircleEl.css('background', '#fff')
            $swiperBottomRightTextEl.css('color', '#1c4058')
            break
        }
        case 3: {
            $pageSwiperEl.css('z-index', 3).removeClass('show-all');
            $swiperAsideEl.css({
                'opacity': 1,
                'visibility': 'visible'
            });
            $asideDianEl.css('background', '#252d67')
            $asideMoreTextEl.css('color', '#b5d9f1')
            $asideMoreIconEl.css('background', '#252d67')
            $swiperBottomEl.css({
                'opacity': 1,
                'visibility': 'visible'
            })
            $swiperBottomContEl.css('background', '#fff')
            $swiperBottomCircleEl.css('border-color', '#b2b2b2')
            $swiperBottomAnimateCircleEl.css('background', '#dcdcdc')
            $swiperBottomRightTextEl.css('color', '#666666')
            break
        }
        case 4: {
            $pageSwiperEl.css('z-index', 3).removeClass('show-all');
            $swiperAsideEl.css({
                'opacity': 1,
                'visibility': 'visible'
            });
            $asideDianEl.css('background', '#cccccc')
            $asideMoreTextEl.css('color', '#666666')
            $asideMoreIconEl.css('background', '#469fdd')
            $swiperBottomEl.css({
                'opacity': 1,
                'visibility': 'visible'
            })
            $swiperBottomContEl.css('background', '#252d67')
            $swiperBottomCircleEl.css('border-color', '#fff')
            $swiperBottomAnimateCircleEl.css('background', '#fff')
            $swiperBottomRightTextEl.css('color', '#a8abc2')
            break
        }
        case 5: {
            $pageSwiperEl.css('z-index', 3).addClass('show-all');
            $swiperAsideEl.css({
                'opacity': 0,
                'visibility': 'hidden'
            });
            $swiperBottomEl.css({
                'opacity': 0,
                'visibility':'hidden'
            })
            break
        }
    }
}

// product select 模拟
$('.index-products .select-item input').on('click',function () {
    let $parentsEl = $(this).parents('.select-item')
    if (!$parentsEl.hasClass('active')){
        $parentsEl.addClass('active')
        $parentsEl.find('.selet-list').slideDown(200)
        // swiperPage.detachEvents();
        swiperPage.mousewheel.disable();
        $('.page-swiper .swiper-container .index-products').addClass('swiper-no-swiping')
    }
})
$('.index-products .select-item .selet-list ul li').on('click', function () {
    let value = $(this).html()
    let id =''
    if ($(this).attr('data-id') != 'undefined'){
        id = $(this).attr('data-id')
        $(this).parents('.select-item').find('input').attr('data-id',id)
    }
    $(this).parents('.select-item').removeClass('active').find('input').val(value)
    $(this).parents('.selet-list').slideUp(200)
    $('.page-swiper .swiper-container .index-products').removeClass('swiper-no-swiping')
    // swiperPage.attachEvents();
    swiperPage.mousewheel.enable();
})

$('body').on('click', function (e) {
    let length = $(e.target).parents('.select-item').length
    if(length == 0){
        $('.index-products .select-item').removeClass('active')
        $('.index-products .selet-list').slideUp(200)
        $('.page-swiper .swiper-container .index-products').removeClass('swiper-no-swiping')
        // swiperPage.attachEvents();
        swiperPage.mousewheel.enable();
    }
})

// product 表单tab切换
!(function () {
    $('.index-products .form-cont .left-tab div').eq(0).addClass('active')
    $('.index-products .form-cont .right-form .select .select-item').eq(0).show()
})()
$('.index-products .form-cont .left-tab div').on('click',function(){
    let number = $(this).index()
    $('.index-products .form-cont .left-tab div').eq(number).addClass('active').siblings().removeClass('active')
    $('.index-products .form-cont .right-form .select .select-item').eq(number).show().siblings().hide()
})